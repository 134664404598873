/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Londres"), React.createElement("p", null, "Pour se loger, Londres s’articule en six quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/westminster.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Westminster"), " : le Parlement britannique, Big Ben et Buckingham Palace."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/chelsea.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Chelsea"), ", ", React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/kensington.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kensington"), " et ", React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/nottinghill.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Notting Hill"), ", à l’ouest : quartiers chics et verdoyants, près de Hyde Park."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/westend.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "West End"), ", au nord : près de Trafalgar Square, avec ", React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/soho.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Soho"), ", ", React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/piccadilly.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Piccadilly"), ", ", React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/mayfair.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Mayfair"), " ou encore ", React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/coventgarden.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Covent Garden"), "."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/camden.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Camden"), ", au nord de West End : pour son marché, ses bars animés et ses petits concerts."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/shoreditch.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Shoreditch"), ", au nord-est : quartier branché de Londres."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/southwark.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Southwark"), ", sur la rive sud de la Tamise : en face de la City, près de la Tate Modern et du Tower Bridge."), "\n"), React.createElement("p", null, "On peut aussi choisir son hébergement en fonction d’une gare à proximité : ", React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/kingscross.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "gares de Saint-Pancras et de King Cross"), ", ", React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/paddington.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "gare de Paddington"), " ou ", React.createElement("a", {
    href: "https://www.booking.com/district/gb/london/victoria.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "gare Victoria"), ".")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
